import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject, from, of } from 'rxjs';
import { Platform } from '@ionic/angular';

import { User, auth } from 'firebase/app';
import { cfaSignIn, cfaSignOut } from 'capacitor-firebase-auth';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class AuthService {

  currentUser: User;
  userProviderAdditionalInfo: any;
  redirectResult: Subject<any> = new Subject<any>();
  user: Observable<firebase.User>;

  constructor(
    public angularFire: AngularFireAuth,
    public platform: Platform,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.user = angularFire.authState;
    if (isPlatformBrowser(this.platformId)) {
      this.angularFire.onAuthStateChanged((user) => {
        if (user) {
          // User is signed in.
          this.currentUser = user;
        } else {
          // No user is signed in.
          this.currentUser = null;
        }
      });

      if (!this.platform.is('capacitor')) {
        // when using signInWithRedirect, this listens for the redirect results
        this.angularFire.getRedirectResult()
        .then((result) => {
          // result.credential.accessToken gives you the Provider Access Token. You can use it to access the Provider API.
          if (result.user) {
            this.userProviderAdditionalInfo = result.additionalUserInfo.profile;
            this.redirectResult.next(result);
          }
        }, (error) => {
          this.redirectResult.next({error: error.code});
        });
      }
    }
  }

  getRedirectResult(): Observable<any> {
    return this.redirectResult.asObservable();
  }

  getPhotoURL(signInProviderId: string, photoURL: string): string {
    // Default imgs are too small and our app needs a bigger image
    switch (signInProviderId) {
      case 'facebook.com':
        return photoURL + '?height=400';
      case 'password':
        return 'https://www.gravatar.com/avatar/';
      case 'twitter.com':
        return photoURL.replace('_normal', '_400x400');
      case 'google.com':
        return photoURL.split('=')[0];
      default:
        return photoURL;
    }
  }

  signOut(): Observable<any> {

    if (this.platform.is('capacitor')) {
      return cfaSignOut();
    } else {
      return from(this.angularFire.signOut());
    }
  }

  signInWithEmail(email: string, password: string): Promise<auth.UserCredential> {
    return this.angularFire.signInWithEmailAndPassword(email, password);
  }

  signUpWithEmail(email: string, password: string): Promise<auth.UserCredential> {
    return this.angularFire.createUserWithEmailAndPassword(email, password);
  }

  socialSignIn(providerName: string, scopes?: Array<string>): Observable<any> {
    if (this.platform.is('capacitor')) {
      return cfaSignIn(providerName);
    } else {
      const provider = new auth.OAuthProvider(providerName);

      if (scopes) {
        scopes.forEach(scope => {
          provider.addScope(scope);
        });
      }

      if (this.platform.is('desktop')) {
        return from(this.angularFire.signInWithPopup(provider));
      } else {
        // web but not desktop, for example mobile PWA
        return from(this.angularFire.signInWithPopup(provider));
      }
    }
  }

  signInWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    const scopes = ['profile', 'email'];
    return this.socialSignIn(provider.providerId, scopes);
  }

}
