import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';

import { Observable, of, concat, forkJoin, throwError, combineLatest } from 'rxjs';
import { map, concatMap, first, filter } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { DataStore, ShellModel } from '../shell/data-store';
import { UserListingItemModel } from '../member/listing/user-listing.model';
import { WaiverListingItemModel } from '../member/listing/waiver-listing.model';
import { UserModel } from '../member/user.model';
import { TransferStateHelper } from '../utils/transfer-state-helper';
import { isPlatformServer } from '@angular/common';
import { VenueModel } from '../model/venue.model';
import { profile } from 'console';

@Injectable()
export class UserService {
  // Listing Page
  private listingDataStore: DataStore<Array<UserListingItemModel>>;
  // User Details Page
  private combinedUserDataStore: DataStore<UserModel>;
  private relatedUsersDataStore: DataStore<Array<UserListingItemModel>>;

  private profileDataStore: DataStore<UserModel>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private transferStateHelper: TransferStateHelper,
    private afs: AngularFirestore
  ) {}

  /*
    Firebase User Listing Page
  */
  public getListingDataSource(): Observable<Array<UserListingItemModel>> {
    const rawDataSource = this.afs.collection<UserListingItemModel>('users').valueChanges({ idField: 'id' })
     .pipe(
       map(actions => actions.map(user => {
          return { ...user } as UserListingItemModel;
        })
      )
    );

    // This method tapps into the raw data source and stores the resolved data in the TransferState, then when
    // transitioning from the server rendered view to the browser, checks if we already loaded the data in the server to prevent
    // duplicate http requests.
    const cachedDataSource = this.transferStateHelper.checkDataSourceState('firebase-listing-state', rawDataSource);

    return cachedDataSource;
  }

  public getListingStore(dataSource: Observable<Array<UserListingItemModel>>): DataStore<Array<UserListingItemModel>> {
    // Use cache if available
    if (!this.listingDataStore) {
      // Initialize the model specifying that it is a shell model
      const shellModel: Array<UserListingItemModel> = [
        new UserListingItemModel()
      ];
      this.listingDataStore = new DataStore(shellModel);

      // If running in the server, then don't add shell to the Data Store
      // If we already loaded the Data Source in the server, then don't show a shell when transitioning back to the broswer from the server
      if (isPlatformServer(this.platformId) || dataSource['ssr_state']) {
        // Trigger loading mechanism with 0 delay (this will prevent the shell to be shown)
        this.listingDataStore.load(dataSource, 0);
      } else { // On browser transitions
        // Trigger the loading mechanism (with shell)
        this.listingDataStore.load(dataSource);
      }
    }

    return this.listingDataStore;
  }

  // Filter users by age
  public searchUsersById(name: string): Observable<Array<UserListingItemModel>> {
    
    const listingCollection = this.afs.collection<UserListingItemModel>('users', ref =>
      ref.where("firstname", "==", name));

    return listingCollection.valueChanges({ idField: 'id' }).pipe(
      map(actions => actions.map(user => {
         return { ...user } as UserListingItemModel;
       })
     ));
  }

  public listWaivers(): Observable<Array<WaiverListingItemModel>> {
    
    const listingCollection = this.afs.collection<WaiverListingItemModel>('waivers', ref =>
      ref.orderBy('dateTime'));

    return listingCollection.valueChanges().pipe(
      map(actions => actions.map(waiver => {
         return { ...waiver } as WaiverListingItemModel;
       })
     ));
  }

  /*
    Firebase Create User Modal
  */
  public createUser(userData: UserModel): Promise<DocumentReference>  {
    return this.afs.collection('users').add({...userData});
  }

  /*
    Firebase Update User Modal
  */
  public updateUser(userData: UserModel): Promise<void> {
    return this.afs.collection('users').doc(userData.id).set(userData);
  }

  public deleteUser(userKey: string): Promise<void> {
    return this.afs.collection('users').doc(userKey).delete();
  }

  public createWaiver(dateTime, fullname, phone, email, spectator, signature): Promise<DocumentReference>  {
    return this.afs.collection('waivers').add({dateTime, fullname, phone, email, spectator, signature});
  }

  

   /*
    Firebase User Details Page
  */
  // Concat the userData with the details of the userSkills (from the skills collection)
  public getCombinedUserDataSource(userId: string): Observable<UserModel> {
    const rawDataSource = this.getUser(userId)
    .pipe(
      // Transformation operator: Map each source value (user) to an Observable (combineDataSources | throwError) which
      // is merged in the output Observable
      concatMap(user => {
        if (user) {

          // Combination operator: Take the most recent value from both input sources (of(user) & forkJoin(userSkillsObservables)),
          // and transform those emitted values into one value ([userDetails, userSkills])
          return combineLatest([
            of(user)
          ]).pipe(
            map(([userDetails]: [UserModel]) => {
              // Spread operator (see: https://dev.to/napoleon039/how-to-use-the-spread-and-rest-operator-4jbb)
              return {
                ...userDetails
              } as UserModel;
            })
          );
        } else {
          // Throw error
          return throwError('User does not have any skills.');
        }
      })
    );

    // This method tapps into the raw data source and stores the resolved data in the TransferState, then when
    // transitioning from the server rendered view to the browser, checks if we already loaded the data in the server to prevent
    // duplicate http requests.
    const cachedDataSource = this.transferStateHelper.checkDataSourceState(`firebase-user-${userId}-state`, rawDataSource);

    return cachedDataSource;
  }

  public getCombinedUserStore(dataSource: Observable<UserModel>): DataStore<UserModel> {
    // Initialize the model specifying that it is a shell model
    const shellModel: UserModel = new UserModel();
    this.combinedUserDataStore = new DataStore(shellModel);

    // If running in the server, then don't add shell to the Data Store
    // If we already loaded the Data Source in the server, then don't show a shell when transitioning back to the broswer from the server
    if (isPlatformServer(this.platformId) || dataSource['ssr_state']) {
      // Trigger loading mechanism with 0 delay (this will prevent the shell to be shown)
      this.combinedUserDataStore.load(dataSource, 0);
    } else { // On browser transitions
      // Trigger the loading mechanism (with shell)
      this.combinedUserDataStore.load(dataSource);
    }

    return this.combinedUserDataStore;
  }

  public getUserByEmail(email: string): Observable<Array<UserModel>> {
    const listingCollection = this.afs.collection<UserModel>('users', ref =>
      ref.where("email", "==", email));

    return listingCollection.valueChanges({ idField: 'id' }).pipe(
        map(actions => actions.map(user => {
           return { ...user } as UserModel;
         })
    ));
  }

  // Get data of a specific User
  private getUser(userId: string): Observable<UserModel> {
    return this.afs.doc<UserModel>('users/' + userId)
    .snapshotChanges()
    .pipe(
      map(a => {
        const userData = a.payload.data();
        const id = a.payload.id;
        return { id, ...userData } as UserModel;
      })
    );
  }

  public getVenuesByOwnerId(userId: string): any {
    return this.afs.collection<VenueModel>('venues', ref =>
      ref.where("owner", "==", userId)).valueChanges({ idField: 'id' })
      .pipe(
        map(actions => actions.map(venue => {
          return { ...venue } as VenueModel;
        }))
    );
  }

  public getVenuesByMemberId(userId: string): Observable<Array<VenueModel>> {
    return this.afs.collection<VenueModel>('venues', ref =>
      ref.where("owner", "==", userId)).valueChanges({ idField: 'id' })
      .pipe(
        map(actions => actions.map(venue => {
          return { ...venue } as VenueModel;
        }))
      );
  }

  public getProfileDataSource(userId: string): Observable<UserModel> {

    const profile = new UserModel();   
    const rawDataSource = this.afs.doc<UserModel>('users/' + userId)
    .valueChanges()
    .pipe(
      map(user => {
        return { ...user } as UserModel;
      })
    );

    // This method tapps into the raw data source and stores the resolved data in the TransferState, then when
    // transitioning from the server rendered view to the browser, checks if we already loaded the data in the server to prevent
    // duplicate http requests.
    const cachedDataSource = this.transferStateHelper.checkDataSourceState('profile-state', rawDataSource);

    return cachedDataSource;
  }

  public getProfileStore(dataSource: Observable<UserModel>): DataStore<UserModel> {
    // Use cache if available
    if (!this.profileDataStore) {
      // Initialize the model specifying that it is a shell model
      const shellModel: UserModel = new UserModel();
      this.profileDataStore = new DataStore(shellModel);

      // If running in the server, then don't add shell to the Data Store
      // If we already loaded the Data Source in the server, then don't show a shell when transitioning back to the broswer from the server
      if (isPlatformServer(this.platformId) || dataSource['ssr_state']) {
        // Trigger loading mechanism with 0 delay (this will prevent the shell to be shown)
        this.profileDataStore.load(dataSource, 0);
      } else { // On browser transitions
        // Trigger the loading mechanism (with shell)
        this.profileDataStore.load(dataSource);
      }
    }

    return this.profileDataStore;
  }

}
