import {
  Component,
  OnInit
} from '@angular/core';
import {
  ModalController,
  NavParams
} from '@ionic/angular';
import moment from 'moment';
import { BookingService } from '../service/booking.service';
import {
  MessageService
} from '../service/message.service';
import {
  BookingModel
} from './booking.model';
import {
  SignaturePage
} from '../waiver-form/signature.page';
import { WaiverListingItemModel } from '../member/listing/waiver-listing.model';

@Component({
  selector: 'app-booking-confirm',
  templateUrl: './confirm.page.html',
  styleUrls: ['./styles/confirm.page.scss']
})

export class ConfirmPage implements OnInit {

  startDay: string;
  startTime: string;
  endTime: string;
  startHour: number;
  user: string;  
  dateFormat = "YYYY-MM-DD";
  opertion: string;

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    public bookingService: BookingService,
    private messageService: MessageService,
  ) {
    if (navParams.data.event.value) {
      this.opertion = 'Remove';
    } else {
      this.opertion = 'Book';
    }
  }

  ngOnInit() {
    this.startDay = moment(this.navParams.data.time).format("MMM Do, YYYY");
    this.startTime = this.convertHour(this.navParams.data.startHour);
    this.endTime = this.convertHour(this.navParams.data.startHour + 1);
    this.user = this.messageService.getUser();;
  }

  confirm() {
    var bookedDay = moment(this.navParams.data.time).format(this.dateFormat);
    var saveBookingModel = new BookingModel();
    saveBookingModel.bookedBy = this.user;
    saveBookingModel.id = `${bookedDay}_${this.navParams.data.startHour.toString().padStart(2, '0')}`;
    saveBookingModel.booked4Date = this.navParams.data.time;
    saveBookingModel.booked4Time = this.startHour.toString();
    saveBookingModel.bookedTimeStamp = new Date();

    saveBookingModel.facilityId = "F000001";

    if (this.opertion === 'Remove') {
      saveBookingModel.action = 'Removed'
      this.bookingService.removeBooking(saveBookingModel.id, saveBookingModel).then(() => {
  //      this.messageService.presentToastWithWarning("Removed", 3000);
        this.modalController.dismiss();
      });
    } else {
      saveBookingModel.action = 'Booked'
      this.bookingService.createBooking(saveBookingModel).then(() => {
 //       this.messageService.presentToastWithSuccess("Booked", 3000);
        this.modalController.dismiss();
      });
    }
  }

  close() {
    this.modalController.dismiss();
  }

  waiver() {
    var user = JSON.parse(localStorage.getItem('user'));
    var vaiverFormData = new WaiverListingItemModel();
    vaiverFormData.dateTime = `${this.startDay} ${this.startTime}`;
    vaiverFormData.email = user.email;
    vaiverFormData.fullname = user.fullname;
    vaiverFormData.phone = '12345';
    vaiverFormData.operation = 'add';
    this.modalController.create({
      component: SignaturePage,
      cssClass: 'fullscreen-modal',
      componentProps: {
        waiver:  vaiverFormData
      }
    }).then(modal => {
      modal.present();
    });
  }

  convertHour(hour): string {
    if (hour > 12) {
      var afternoon = hour % 12;
      return `${afternoon.toString().padStart(2, "")}:00pm`;
    } else if (hour == 12) {
      return `${hour.toString().padStart(2, "")}:00pm`;
    } else {
      return `${hour.toString().padStart(2, "")}:00am`;
    }
  }
}