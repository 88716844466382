import { ShellModel } from '../../shell/data-store';

export class WaiverListingItemModel extends ShellModel {
  signature: string;
  fullname: string;
  email: string;
  phone: string;
  spectator: string;
  dateTime: string;
  operation: string;

  constructor() {
    super();
  }
}
