<ion-app dir="{{textDir}}">
  <ion-split-pane contentId="menu-content" when="md">
    <ion-menu contentId="menu-content" class="app-sidemenu">
      <ion-header>
        <ion-toolbar>
          <ion-row>
            <ion-col size="3">
              <app-aspect-ratio [ratio]="{w: 1, h: 1}">
                <app-image-shell [display]="'cover'" class="user-avatar" animation="spinner" [src]="avatar"
                  [alt]="'avatar'">
               </app-image-shell>
              </app-aspect-ratio>
            </ion-col>
            <ion-col class="user-info-wrapper" size="8">
              <h3 class="user-name">Booking</h3>
              <h5 class="user-handle">{{username}}</h5>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerLink]="['/profile']">
              <ion-icon slot="start" name="settings-outline"></ion-icon>
              <ion-label>
                Settings
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <ion-list>
          <ion-list-header>
            <ion-label>Home</ion-label>
          </ion-list-header>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item [routerLink]="p.url">
              <ion-icon slot="start" [name]="p.ionicIcon? p.ionicIcon: ''" [src]="p.customIcon? p.customIcon: ''"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <ion-list *ngIf="isOwner">
          <ion-list-header>
            <ion-label>Owner</ion-label>
          </ion-list-header>
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerLink]="['/venues']">
              <ion-icon slot="start" name="location-outline"></ion-icon>
              <ion-label>
                Venues
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerLink]="['/member/listing']">
              <ion-icon slot="start" name="person-add-outline"></ion-icon>
              <ion-label>
                Members
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerLink]="['/memberships']">
              <ion-icon slot="start" name="medal-outline"></ion-icon>
              <ion-label>
                Memberships
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <ion-list>
          <ion-list-header>
            <ion-label>Account</ion-label>
          </ion-list-header>
          <ion-menu-toggle auto-hide="false">
            <!-- <ion-item *ngIf="!isLoggedIn" [routerLink]="['/auth/signup']">
              <ion-icon slot="start" name="person-add-outline"></ion-icon>
              <ion-label>
                Sign up
              </ion-label>
            </ion-item> -->
            <ion-item *ngIf="!isLoggedIn" [routerLink]="['/auth/login']">
              <ion-icon slot="start" name="log-in-outline"></ion-icon>
              <ion-label>
                Sign in
              </ion-label>
            </ion-item>
            <ion-item *ngIf="isLoggedIn" button (click)="logout()">
              <ion-icon slot="start" name="log-out-outline"></ion-icon>
              <ion-label>
                Sign out
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <!-- Main app content get's rendered in this router-outlet -->
    <ion-router-outlet id="menu-content"></ion-router-outlet>
  </ion-split-pane>
  
</ion-app>
