import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';

import { Observable, of, forkJoin, throwError, combineLatest } from 'rxjs';
import { map, concatMap, first, filter } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { DataStore, ShellModel } from '../shell/data-store';
import { TransferStateHelper } from '../utils/transfer-state-helper';
import { isPlatformServer } from '@angular/common';
import { BookingModel } from '../booking/booking.model';

@Injectable()
export class BookingService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private transferStateHelper: TransferStateHelper,
    private afs: AngularFirestore
  ) {}

  public createBooking(bookingData: BookingModel): Promise<void>  {
    this.afs.collection('bookingsHistory').add({...bookingData});
    return this.afs.collection('bookings').doc(bookingData.id).set({...bookingData});
  }
  
  public createBookingHistory(bookingData: BookingModel): Promise<DocumentReference>  {
    return this.afs.collection('bookingsHistory').add({...bookingData});
  }

  public removeBooking(bookingKey: string, bookingData: BookingModel): Promise<void>  {
    this.afs.collection('bookingsHistory').add({...bookingData});
    return this.afs.collection('bookings').doc(bookingKey).delete();
  }

   // Filter booking by date
  public searchBookingByDate(bookingDate: Date): Observable<Array<BookingModel>> {
   
    const listingCollection = this.afs.collection<BookingModel>('bookings', ref =>
      ref.orderBy('booked4Time').startAfter(bookingDate));

    return listingCollection.valueChanges().pipe(
      map(actions => actions.map(booking => {
         return { ...booking } as BookingModel;
       })
     ));
  }

  // Filter booking by member
  public searchBookingByMember(bookedDate: Date, member: string): Observable<Array<BookingModel>> {
   
    const listingCollection = this.afs.collection<BookingModel>('bookings', ref =>
      ref.orderBy('id', 'asc').where('bookedBy', '==', member));

    return listingCollection.valueChanges().pipe(
      map(actions => actions.map(booking => {
         return { ...booking } as BookingModel;
       })
     ));
  }

  // Filter booking history by member
  public searchBookingHistoryByMember(id: string, member: string): Observable<Array<BookingModel>> {
   
    const listingCollection = this.afs.collection<BookingModel>('bookingsHistory', ref =>
      ref.orderBy('bookedTimeStamp', 'desc').where('bookedBy', '==', member));

    return listingCollection.valueChanges().pipe(
      map(actions => actions.map(booking => {
         return { ...booking } as BookingModel;
       })
     ));
  }

}
