<ion-content>
  <ion-list lines="full">
    <ion-item>
      <ion-label>{{startDay}}</ion-label>
    </ion-item>
    <ion-item>
      <ion-label>Start</ion-label>
      <ion-label>{{startTime}}</ion-label>
    </ion-item>
    <ion-item>
      <ion-label>End</ion-label>
      <ion-label>{{endTime}}</ion-label>
    </ion-item>
    <ion-item>
      <ion-label>Booked By</ion-label>
      <ion-label>{{user}}</ion-label>
    </ion-item>
  </ion-list>

  <ion-row size="12">
    <ion-col class="actions-col" size="4">
      <ion-button (click)="confirm()" color="success">
        {{opertion}}
      </ion-button>
    </ion-col>
    <ion-col class="actions-col" size="4">
      <ion-button (click)="waiver()" color="primary">
        Waiver
      </ion-button>
    </ion-col>
    <ion-col class="actions-col" size="4">
      <ion-button (click)="close()" color="secondary">
        Cancel
      </ion-button>
    </ion-col>
  </ion-row>

</ion-content>