<ng-content></ng-content>
<a class="type-toggle" (click)="toggleShow()">
	<ion-icon class="show-option" [hidden]="show" name="eye-off-outline"></ion-icon>
	<ion-icon class="hide-option" [hidden]="!show" name="eye-outline"></ion-icon>
  <!-- In case you want to use text instead of icons -->
	<!--
  <span class="show-option" [hidden]="show">show</span>
	<span class="hide-option" [hidden]="!show">hide</span>
  -->
</a>
