<div class="button-outer">
  <div class="button-wrapper">
    <ion-button class="counter-icon" (click)="decrease()">
      <ion-icon slot="icon-only" name="remove"></ion-icon>
    </ion-button>
  </div>
</div>
<span class="counter-value">{{ _counterValue }}</span>
<div class="button-outer">
  <div class="button-wrapper">
    <ion-button class="counter-icon" (click)="increase()">
      <ion-icon slot="icon-only" name="add"></ion-icon>
    </ion-button>
  </div>
</div>
