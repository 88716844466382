import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule} from '@ionic/angular';
import { ConfirmPage } from './confirm.page';
import { RouterModule, Routes } from '@angular/router';
import { UserService } from '../service/user.service';

const confirmRoutes: Routes = [
  {
    path: '',
    component: ConfirmPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule.forChild(confirmRoutes),
  ],
  providers: [
    UserService
  ],
  declarations: [ConfirmPage],
})

export class ConfirmPageModule {}
