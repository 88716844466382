import { Component } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { SeoService } from './utils/seo/seo.service';
const { SplashScreen } = Plugins;
import { TranslateService } from '@ngx-translate/core';
import { HistoryHelperService } from './utils/history-helper.service';
import { from } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss'
  ]
})
export class AppComponent {
  appPages = [
    {
      title: 'Booking',
      url: '/app/booking',
      ionicIcon: 'list-outline'
    },
    {
      title: 'My Booking',
      url: '/app/mybooking',
      ionicIcon: 'bookmark-outline'
    },
    {
      title: 'Booking History',
      url: '/app/booking-history',
      ionicIcon: 'time-outline'
    },
    {
      title: 'Vaivers Form',
      url: '/app/waivers',
      ionicIcon: 'information-circle-outline'
    }
  ];
 
  textDir = 'ltr';
  isLoggedIn = false;
  user: any;

  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    public router: Router,
    public angularFire: AngularFireAuth,
    public translate: TranslateService,
    public historyHelper: HistoryHelperService,
    private seoService: SeoService
  ) {
    this.initializeApp();
    this.setLanguage();
  }

  get venue() {
    return 'WeGolf'
  }

  get avatar() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user && this.user.avatar) {
      return this.user.avatar
    } else {
      return './assets/sample-images/ionicthemes_logo.jpg'
    }
  }

  get username() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user && this.user.fullname) {
      this.isLoggedIn = true
      return this.user.fullname;
    } else {
      this.isLoggedIn = false;
      return '';
    }
  }

  get isOwner() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user && this.user.role && this.user.role === 'Owner') {
      return true;
    } else {
      return false;
    }
  }

  // get userrole() {
  //   this.user = JSON.parse(localStorage.getItem('user'));
  //   var userrole;
  //   if (this.user && this.user.role && this.user.role === 'Owner') {
  //     if (this.user.role === 'Owner') {
  //       userrole = `Owner - ${this.user.venueName}`
  //     } else {
  //       if (this.user.venueName) {
  //         userrole = `Member - ${this.user.venueName}`
  //       } else {
  //         userrole = `Member`
  //       }
  //     }   
  //   } else {
  //     userrole = `Member`
  //   }
  //   return userrole;
  // }

  logout() {
    localStorage.removeItem('user');
    this.isLoggedIn = JSON.parse(localStorage.getItem('user')) !== null;
    from(this.angularFire.signOut());
    this.router.navigate(['auth/login'], { replaceUrl: true });
  }

  async initializeApp() {
    try {
     await SplashScreen.hide();
    } catch (err) {
     console.log('This is normal in a browser', err);
    }
  }

  setLanguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');

    // this is to determine the text direction depending on the selected language
    // for the purpose of this example we determine that only arabic and hebrew are RTL.
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
    // });
  }

}
