import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../service/user.service';
import SignaturePad from 'signature_pad';
import { SignatureModule } from './signature.module';
import { escapeRegExp } from '@angular/compiler/src/util';
import { MessageService } from '../service/message.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.page.html',
  styleUrls: [
    './styles/signature.scss',
    './styles/signature.shell.scss'
  ],
})
export class SignaturePage implements OnInit, AfterViewInit {

  @ViewChild('signaturePad', {static: true}) signaturePadElement;
  signaturePad: any;

  signitureForm: FormGroup;
  isNew: boolean;

  constructor(
    public navParams: NavParams,
    public modalController: ModalController,
    public userService: UserService,
    public messageService: MessageService
  ) { }

  ngOnInit(): void {
    var vaiverData = this.navParams.data.waiver;
    if (vaiverData.operation === 'add') {
      this.isNew = true;
    } else {
      this.isNew = false;
    }
    this.signitureForm = new FormGroup({
      datetime: new FormControl(vaiverData.dateTime, Validators.required),
      fullname: new FormControl(vaiverData.fullname, Validators.required),
      email: new FormControl(vaiverData.email, Validators.required),
      phone: new FormControl(vaiverData.phone, Validators.required),
      spectator: new FormControl(),
      operation: new FormControl(vaiverData.operation, Validators.required)
    });
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, { backgroundColor: 'rgb(255, 255, 255)' });
    if (!this.isNew) {
      this.signaturePad.fromDataURL(this.navParams.data.waiver.signature);
    } 

    function resizeCanvas() {
      var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    
      this.signaturePadElement.nativeElement.width = this.signaturePadElement.nativeElement.offsetWidth * ratio;
      this.signaturePadElement.nativeElement.height = this.signaturePadElement.nativeElement.offsetHeight * ratio;
      this.signaturePadElement.nativeElement.getContext("2d").scale(ratio, ratio);
    
      this.signaturePad.clear();
    }

    window.onresize = resizeCanvas;
    resizeCanvas();
    
  }

  dismissModal() {
   this.modalController.dismiss();
  }

  clear() {
    this.signaturePad.clear();
  }

  agree() {
    var dateTime = this.signitureForm.value.datetime;
    var fullname = this.signitureForm.value.fullname;
    var phone = this.signitureForm.value.phone;
    var email = this.signitureForm.value.email;
    var spectator = this.signitureForm.value.spectator;
    var signature = this.signaturePad.toDataURL();
    
    if (this.signaturePad.isEmpty()) {
      this.messageService.presentToastWithWarning("No signature provided", 2000);
    } else {
      this.userService.createWaiver(dateTime, fullname, phone, email, spectator, signature)
      .then(
        () => this.modalController.dismiss(),
        err => console.log(err)
      );
    }
  }

  
}
