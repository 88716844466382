import { ShellModel } from '../../shell/data-store';

export class UserListingItemModel extends ShellModel {
  image: string;
  firstname: string;
  lastname: string;
  id: string;

  constructor() {
    super();
  }
}
