import { ShellModel } from '../shell/data-store';

export class BookingModel extends ShellModel {
  id: string;
  booked4Date: Date;
  booked4Time: string;
  booked: boolean;
  disabled: boolean;
  color: string;
  bookedBy: string;
  facilityId: string;
  action: string;
  bookedTimeStamp: Date;
 
  constructor() {
    super();
  }
}

