<form class="update-user-form ion-page" [formGroup]="signitureForm" (ngSubmit)="agree()">
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons slot="end">
        <ion-button (click)="dismissModal()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>COVID-19 Liability Waiver</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content class="update-user-content">
    <section class="user-details-fields fields-section">
      <ion-list class="inputs-list" lines="full">
        <ion-item class="input-item">
          <ion-label color="secondary" position="floating">Date & Time</ion-label>
          <ion-input type="text" formControlName="datetime" required readonly=true></ion-input>
        </ion-item>
        <ion-item class="input-item">
          <ion-label color="secondary" position="floating">Name</ion-label>
          <ion-input type="text" formControlName="fullname" required readonly=true></ion-input>
        </ion-item>
        <ion-item class="input-item">
          <ion-label color="secondary" position="floating">Phone number</ion-label>
          <ion-input type="text" formControlName="phone" required readonly=true></ion-input>
        </ion-item>
        <ion-item class="input-item">
          <ion-label color="secondary" position="floating">Email</ion-label>
          <ion-input type="email" formControlName="email" required readonly=true></ion-input>
        </ion-item>
        <ion-item class="input-item">
          <ion-label color="secondary" position="floating">If the player is a minor, signing,</ion-label>
          <ion-label color="secondary" position="floating">Parent/Guardian/Spectator: </ion-label>
          <ion-input type="text" formControlName="spectator"></ion-input>
        </ion-item>
        <p>
          To file COVID-19, we ask parents to stay out of the facility unless it's absolute necessary to accompany young
          player.
        </p>
        <p>
          I acknowledge the contagious nature of the Coronavirus/COVID-19 and that the CDC and many other public health
          authorities still recommend practicing social distancing.
        </p>
        <p>
          I further acknowledge that TopKids Education Center and WegGolf have put in place preventative measures to
          reduce the spread of the Coronavirus/COVID-19.
        </p>
        <p>
          I further acknowledge that TopKids Education Center and WegGolf can not guarantee that I will not become
          infected with the Coronavirus/Covid-19. I understand that the risk of becoming exposed to and/or infected by
          the Coronavirus/COVID-19 may result from the actions, omissions, or negligence of myself and others,
          including, but not limited to, WeGolf staff, and other WeGolf members and their families.
        </p>
        <p>
          I voluntarily seek services provided by WegGolf and acknowledge that I am increasing my risk to exposure to
          the Coronavirus/COVID-19. I acknowledge that I must comply with all set procedures to reduce the spread while
          attending my appointment.
        </p>
        <p>
          I attest that:
        </p>
        <p>
          * I am not experiencing any symptom of illness such as cough, shortness of breath or difficulty breathing,
          fever, chills, repeated shaking with chills, muscle pain, headache, sore throat, or new loss of taste or
          smell.
        </p>
        <p>
          * I have not traveled internationally within the last 14 days.
        </p>
        <p>
          * I have not traveled to a highly impacted area within the United States of America in the last 14 days.
        </p>
        <p>
          * I do not believe I have been exposed to someone with a suspected and/or confirmed case of the
          Coronavirus/COVID-19.
        </p>
        <p>
          * I have not been diagnosed with Coronavirus/Covid-19 and not yet cleared as non contagious by state or local
          public health authorities.
        </p>
        <p>
          * I am following all CDC recommended guidelines as much as possible and limiting my exposure to the
          Coronavirus/COVID-19.
        </p>
        <p>
          I hereby release and agree to hold TopKids Education Center and WegGolf harmless from, and waive on behalf of
          myself, my heirs, and any personal representatives any and all causes of action, claims, demands, damages,
          costs, expenses and compensation for damage or loss to myself and/or property that may be caused by any act,
          or failure to act of TopKids Education Center and WegGolf, or that may otherwise arise in any way in
          connection with any services received from WeGolf. I understand that this release discharges TopKids Education
          Center and WegGolf from any liability or claim that I, my heirs, or any personal representatives may have
          against the WeGolf with respect to any bodily injury, illness, death, medical treatment, or property damage
          that may arise from, or in connection to, any services received from TopKids Education Center and WegGolf.
          This liability waiver and release extends to TopKids Education Center together with all owners, partners, and
          employees.
        </p>
        <ion-label color="secondary" position="floating">Signature</ion-label>
        <ion-item class="input-item" readonly="true"> 
          <canvas  #signaturePad width="320" height="160" style="touch-action: none; border: thin dotted red"></canvas>
        </ion-item>
      </ion-list>
    </section>

  </ion-content>

  <ion-footer *ngIf="isNew">
    <ion-row class="form-actions-wrapper">
      <ion-col>
        <ion-button class="submit-btn" expand="block" color="secondary" (click)="clear()" fill="solid">Clear
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button class="submit-btn" expand="block" color="success" type="submit" fill="solid" [disabled]="!signitureForm.valid">Agree</ion-button>
      </ion-col>
    </ion-row>
  </ion-footer>
</form>