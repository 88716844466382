import { ShellModel } from '../shell/data-store';
import { VenueModel } from '../model/venue.model';

export class UserModel extends ShellModel {
  id: string;
  avatar: string;
  firstname: string;
  lastname: string;
  fullname: string;
  email: string;
  phone: number;
  role: string;
  venueName: string;
  venueId: string;
  membership: string;
  venues: Array<VenueModel>;
 
  constructor() {
    super();
  }
}

