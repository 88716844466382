import {
    Injectable
} from '@angular/core';
import {
    MenuController,
    LoadingController,
    AlertController,
    ToastController
} from '@ionic/angular';
import {
    Router,
    NavigationStart
} from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class MessageService {

    isLoading = false;
    constructor(
        private loadingController: LoadingController,
        private alertController: AlertController,
        private toastController: ToastController,
        public menuCtrl: MenuController,
        private router: Router
    ) {}

    getUser() {
        var user = JSON.parse(localStorage.getItem('user'));
        if (user && user.email) {
            user = user.email;
        } else {
  //          this.presentToastWithWarning("Sign in please", 3000);
            this.router.navigate['auth/login'];
        }
        return user;
    }

    async presentLoading() {
        this.isLoading = true;
        return await this.loadingController.create({
            spinner: "crescent",
            duration: 10000,
            //   message: 'Please wait...',
            translucent: true,
            cssClass: 'loadingDialog'
            //duration: 5000,
        }).then(a => {
            a.present().then(() => {
                console.log('presented');
                if (!this.isLoading) {
                    a.dismiss().then(() => console.log('abort presenting'));
                }
            });
        });
    }

    async presentTimeoutLoading(timeout: number, translucent: boolean) {
        this.isLoading = true;
        return await this.loadingController.create({
            spinner: "crescent",
            duration: timeout,
            //   message: 'Please wait...',
            translucent: translucent,
            cssClass: 'loadingDialog'
            //duration: 5000,
        }).then(a => {
            a.present().then(() => {
                console.log('presented');
                if (!this.isLoading) {
                    a.dismiss().then(() => console.log('abort presenting'));
                }
            });
        });
    }

    async dismissLoading() {
        this.isLoading = false;
        return await this.loadingController.dismiss().then(() => console.log('dismissed'));
    }

    async presentAlert(message) {
        const alert = await this.alertController.create({
            header: 'Alert',
            subHeader: '',
            message: message,
            buttons: ['OK']
        });
        await alert.present();
    }

    async presentToastWithWarning(message, duration) {
        this.presentToastWithOptions('', 'alert-circle-outline', '', message, 'top', duration, 'secondary')
    }

    async presentToastWithSuccess(message, duration) {
        this.presentToastWithOptions('', 'alert-circle-outline', '', message, 'top', duration, "success")
    }

    async presentToastWithOptions(header, iconname, icontext, msg, position, duration, color) {
        const toast = await this.toastController.create({
            header: header,
            message: msg,
            duration: duration,
            color: color,
            position: position,
            buttons: [{
                side: 'start',
                icon: iconname,
                text: icontext,
                handler: () => {
                    console.log('Favorite clicked');
                }
            }, {
                text: 'Done',
                role: 'cancel',
                handler: () => {
                    console.log('Cancel clicked');
                }
            }]
        });
        toast.present();
    }
}